<template>
  <div>
    <v-container fluid fill-height grid-list-md style="margin-top:60px;">
      <v-layout row wrap justify-center>
        <v-card v-if="!$route.query.action" style="max-width: 500px;">
          <v-form>
            <v-flex xs12>
              <p class="login_title text-center">Sign In</p>
              <p class="login_subtitle text-center font">
                With your Kamccu Account
              </p>
            </v-flex>
            <v-flex xs12 style="padding: 30px;">
              <ValidationObserver v-slot="{ handleSubmit }">
                <v-form @submit.prevent="handleSubmit(logIn)">
                  <v-layout row wrap>
                    <v-flex xs12>
                      <ValidationProvider v-slot="{ errors }" rules="required">
                        <v-text-field
                          type="text"
                          label="Account Number"
                          filled
                          :error-messages="errors[0]"
                          clearable
                          class="input_text"
                          autofocus
                          v-model="login.accountNumber"
                          @keyup.enter="logIn"
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-flex>
                    <v-flex xs12>
                      <ValidationProvider v-slot="{ errors }" rules="required">
                        <v-text-field
                          type="password"
                          label="Password"
                          filled
                          :error-messages="errors[0]"
                          clearable
                          class="input_text"
                          v-model="login.password"
                          @keyup.enter="logIn"
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-flex>
                    <v-flex xs12 md12 lg12>
                      <v-layout row wrap>
                        <v-flex xs12 md8 lg8>
                          <v-checkbox
                            class="login_text font font-md text-capitalize"
                            label="Remember Me"
                            v-model="login.remember_me"
                          >
                          </v-checkbox>
                        </v-flex>
                        <v-flex xs12 lg3 offset-md9 offset-lg9>
                          <v-btn
                            type="submit"
                            text
                            outlined
                            :loading="isLoading"
                            :disabled="isLoading"
                            color="primary"
                            class="login_button"
                          >
                            Login
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-form>
              </ValidationObserver>
            </v-flex>
            <v-divider></v-divider>
            <v-flex xs12 style="background-color: #f3f3f3fa;">
              <p class="create_accounts text-center font font-md pt-2">
                Don't have an account? Kindly contact administrator
              </p>
              <p
                @click.stop="goToReset"
                class="create_accounts text-center font font-md pt-2 text-decoration-underline"
                style="cursor: pointer;"
              >
                Forgot Password? Click here to reset.
              </p>
            </v-flex>
          </v-form>
        </v-card>
        <v-card v-else style="max-width: 500px;">
          <v-form>
            <v-flex xs12>
              <p class="login_title text-center">Reset Password</p>
              <p class="login_subtitle text-center font">
                Change your forgotten password here.
              </p>
            </v-flex>
            <v-flex xs12 style="padding: 30px;">
              <ValidationObserver v-slot="{ handleSubmit }">
                <v-form @submit.prevent="handleSubmit(resetPassword)">
                  <v-layout row wrap>
                    <v-flex xs12>
                      <ValidationProvider v-slot="{ errors }" rules="required">
                        <v-text-field
                          type="text"
                          label="Enter your Account Number"
                          filled
                          :error-messages="errors[0]"
                          clearable
                          class="input_text"
                          autofocus
                          v-model="reset.accountNumber"
                        >
                        </v-text-field>
                      </ValidationProvider>
                      <div class="d-flex flex-row flex-grow-1 justify-end">
                        <v-btn
                          :disabled="!reset.accountNumber || isLoading"
                          :loading="isLoading"
                          small
                          @click="
                            $store.dispatch('auth/forgotPassword', {
                              accountNumber: reset.accountNumber,
                            })
                          "
                          rounded
                          color="primary"
                          class="mt-n3"
                        >
                          <span class="ft">Request Reset Code</span>
                        </v-btn>
                      </div>
                    </v-flex>
                    <template v-if="forgotPasswordState">
                      <v-flex xs12>
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            type="tet"
                            label="Enter reset code"
                            filled
                            :error-messages="errors[0]"
                            clearable
                            class="input_text"
                            v-model="reset.code"
                          >
                          </v-text-field>
                        </ValidationProvider>
                      </v-flex>
                      <v-flex xs12>
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            type="text"
                            label="Enter PIN"
                            filled
                            :error-messages="errors[0]"
                            clearable
                            class="input_text"
                            v-model="reset.pin"
                          >
                          </v-text-field>
                        </ValidationProvider>
                      </v-flex>
                      <v-flex xs12>
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            type="text"
                            label="Confirm PIN"
                            filled
                            :error-messages="errors[0]"
                            clearable
                            class="input_text"
                            v-model="reset.confirmPin"
                            @keyup.enter="logIn"
                          >
                          </v-text-field>
                        </ValidationProvider>
                      </v-flex>
                    </template>
                    <v-flex xs12 md12 lg12>
                      <v-layout row wrap>
                        <v-flex xs12 md8 lg8>
                          <v-checkbox
                            class="login_text font font-md text-capitalize"
                            style="visibility: hidden;"
                            label="Remember Me"
                            v-model="login.remember_me"
                          >
                          </v-checkbox>
                        </v-flex>
                        <v-flex
                          class=" d-flex flex-grow-1 justify-end"
                          :style="{
                            visibility: !forgotPasswordState
                              ? 'hidden'
                              : 'visible',
                          }"
                        >
                          <v-btn
                            type="submit"
                            text
                            outlined
                            :loading="isLoading"
                            :disabled="isLoading"
                            color="primary"
                            class="login_button"
                          >
                            Reset Password
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-form>
              </ValidationObserver>
            </v-flex>
            <v-divider></v-divider>
            <v-flex xs12 style="background-color: #f3f3f3fa;">
              <p class="create_accounts text-center font font-md pt-2">
                Don't have an account? Kindly contact administrator
              </p>
              <p
                @click.stop="goToLogin()"
                class="create_accounts text-center font font-md pt-2 text-decoration-underline"
                style="cursor: pointer;"
              >
                Go back to Login
              </p>
            </v-flex>
          </v-form>
        </v-card>

        <!-- snackbar settings here-->
        <SnackBar
          :showSnackBar="showSnackBar"
          :message="message"
          :status="status"
        />
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { loadComponent } from '@/utils/helpers';
import SnackBarMixins from '../../mixins/SnackBarMIxins';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { mapGetters } from 'vuex';
// Add the required rule
extend('required', {
  ...required,
  message: 'This field is required',
});

export default {
  name: 'HomePage',
  props: {
    msg: String,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SnackBar: loadComponent(`component/SnackBar`),
  },
  computed: {
    ...mapGetters({
      isLoading: 'getIsLoading',
      forgotPasswordState: 'auth/getForgotPasswordState',
      resetPasswordState: 'auth/getResetPasswordState',
    }),
  },
  data: () => ({
    login: {
      accountNumber: '',
      password: '',
    },
    reset: {
      pin: null,
      confirmPin: null,
      code: null,
      accountNumber: null,
    },
    currentPage: 'LOGIN',
  }),
  watch: {
    resetPasswordState(value) {
      if (value) {
        this.currentPage = 'LOGIN';
        this.$store.commit('auth/UPDATE_RESET_PASSWORD', null);
        this.$store.commit('auth/UPDATE_FORGOT_PASSWORD', null);
        this.$router.push({
          query: null,
        });
      }
    },
    '$route.query.action': {
      handler(value) {
        if (!value) {
          this.currentPage = 'LOGIN';
        } else {
          this.currentPage = 'RESET';
        }
      },
      deep: true,
    },
  },
  mixins: [SnackBarMixins],
  methods: {
    goToLogin() {
      this.$router.push({
        query: null,
      });
    },
    goToReset() {
      this.$router.push({
        query: {
          action: 'RESET',
        },
      });
    },
    resetPassword() {
      this.$store.dispatch('auth/resetPassword', this.reset);
    },
    logIn() {
      this.$store.dispatch('auth/logIn', this.login);
    },
  },
};
</script>
<!-- Scoped sytle for Login component only-->
<style scoped lang="scss">
$font-family: 'Montserrat', sans-serif;
$center-align: center;

%font-data {
  font-family: $font-family;
}
.msg {
  font-family: $font-family !important;
  font-size: 16px;
  font-weight: 500;
}
.login_title {
  @extend %font-data;
  text-align: center;
  padding: 20px 20px 0 20px;
  font-size: 28px;
}
.input_text {
  @extend %font-data;
  font-size: 18px;
}
.login_button {
  font-size: 14px;
  @extend %font-data;
}
.create_account {
  @extend %font-data;
  font-size: 16px;
  text-align: center;
  padding-top: 15px;
}
</style>
